import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { StreamingDevice } from '../../types/streamingDevices';
import { EventState } from '../../types/events';
import config from '../../config';

interface SpecialEventAuthParams {
  email: string | null;
  eventKey: string | undefined;
}

interface SpecialEventsResponse {
  name: string;
  expires_in: string;
  state: EventState;
  streams: StreamingDevice[];
}

export const specialEventsApi = createApi({
  reducerPath: 'specialEventsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiClientUrl
  }),

  endpoints: (builder) => ({
    authSpecialEvent: builder.query<void, SpecialEventAuthParams>({
      query: ({ email, eventKey }) => `/events/${eventKey}/login?email=${email}`
    }),
    getSpecialEvent: builder.query<SpecialEventsResponse, SpecialEventAuthParams>({
      query: ({ email, eventKey }) => `/events/${eventKey}?email=${email}&_=${new Date().getTime()}`
    })
  })
});

export const { useLazyAuthSpecialEventQuery, useGetSpecialEventQuery } = specialEventsApi;
