import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setCurrentUserProfile, setIsLoggedIn } from '../user/slice';
import config from '../../config';

interface LoginRequest {
  username: string;
  password: string;
}

interface ForgotPasswordRequest {
  username: string;
}

interface CreatePasswordRequest {
  username: string;
  newPassword: string;
  session?: string;
}

interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
  username: string;
}

interface ConfirmForgotPasswordPasswordRequest {
  username: string;
  password: string;
  code: string;
}

interface LearnMoreRequest {
  name: string;
  email: string;
  company: string;
  phone_number: string;
}

type SessionToken = string;

interface NewPasswordRequiredResponse {
  status: 'NEW_PASSWORD_REQUIRED';
  session: SessionToken;
  message: 'New password is required';
}

interface ValidUserResponse {
  status?: 'OK';
  message: 'Valid user.';
}

type LoginSuccessResponse = NewPasswordRequiredResponse | ValidUserResponse;

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl}/auth`,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.set('Cache-Control', 'no-cache');
      headers.set('Pragma', 'no-cache');

      return headers;
    }
  }),

  endpoints: (builder) => ({
    authenticateUser: builder.mutation<LoginSuccessResponse, LoginRequest>({
      query: (credentials) => ({
        url: '/login',
        method: 'POST',
        body: credentials
      })
    }),
    forgotPassword: builder.mutation<ValidUserResponse, ForgotPasswordRequest>({
      query: (credentials) => ({
        url: '/forgot-password',
        method: 'POST',
        body: credentials
      })
    }),
    createPassword: builder.mutation<ValidUserResponse, CreatePasswordRequest>({
      query: (credentials) => ({
        url: '/change-password',
        method: 'POST',
        body: credentials
      })
    }),
    changePassword: builder.mutation<ValidUserResponse, ChangePasswordRequest>({
      query: (credentials) => ({
        url: '/profile/change-password',
        method: 'POST',
        body: credentials
      })
    }),
    confirmForgotPassword: builder.mutation<
      ValidUserResponse,
      ConfirmForgotPasswordPasswordRequest
    >({
      query: (credentials) => ({
        url: '/confirm-forgot-password',
        method: 'POST',
        body: credentials
      })
    }),
    learnMore: builder.mutation<ValidUserResponse, LearnMoreRequest>({
      query: (credentials) => ({
        url: '/register',
        method: 'POST',
        body: credentials
      })
    }),
    logout: builder.mutation<ValidUserResponse, void>({
      query: () => ({
        url: '/logout',
        method: 'POST'
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        await queryFulfilled;
        window.location.href = '/';
      }
    }),
    verify: builder.query<ValidUserResponse, void>({
      query: () => `/verify?_=${new Date().getTime()}`,
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(setIsLoggedIn(true));
        } catch {
          dispatch(setIsLoggedIn(false));
          dispatch(setCurrentUserProfile(null));
        }
      }
    })
  })
});

export const {
  useAuthenticateUserMutation,
  useLogoutMutation,
  useVerifyQuery,
  useLazyVerifyQuery,
  useForgotPasswordMutation,
  useCreatePasswordMutation,
  useChangePasswordMutation,
  useLearnMoreMutation,
  useConfirmForgotPasswordMutation
} = authApi;
