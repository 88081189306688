import { toast, ToastContainer } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { deviceTypeOptions } from '../StreamingDevices/deviceOptions';
import { ROLES } from '../../types/users';
import { StreamingDevice, StreamingDeviceType } from '../../types/streamingDevices';
import { OrganizationNameAndID } from '../../types/organizations';
import { ApiListResponse } from '../../types/api';
import { selectCurrentUser } from '../../store/user/selectors';
import {
  useGetStreamingDeviceQuery,
  useGetStreamingDevicesByOrganizationIdQuery,
  useLazyGetStreamingDeviceQuery
} from '../../store/services/streamingDevicesApi';
import {
  useLazyGetOrganizationByIdQuery,
  useOrganizationsQuery
} from '../../store/services/organizationsApi';
import { handleCatch } from '../../store/services/helpers';
import { useEventsQuery } from '../../store/services/eventsApi';
import useTitle from '../../helpers/useTitle';
import VideoGrid from '../../components/VideoGrid';
import Select from '../../components/Select';
import ReactPlayer from '../../components/ReactPlayer';
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';
import InputField from '../../components/Input';
import CoverImage from '../../components/CoverImage';
import { Sort } from '../../components/CNCTable/helpers';
import CNCTable from '../../components/CNCTable';
import liveStreams from '../../assets/live-streams.png';
import defaultUser from '../../assets/default-user.png';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';

import { inactiveDevicesColumns } from './InactiveDevices.table';
// keep this order since it's overriding  some ReactToastify.css variable

import 'react-toastify/dist/ReactToastify.css';
import './Dashboard.scss';

const PAGE_SIZE = 12;

type DeviceTypeFilterOption = { value: StreamingDeviceType | string; label: string };

const DEVICE_TYPE_FILTER_OPTIONS: DeviceTypeFilterOption[] = [
  { value: '', label: 'All' },
  ...deviceTypeOptions
];

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const playerRef = useRef<HTMLDivElement>(null);

  const [initialDeviceSet, setInitialDeviceSet] = useState<boolean>(false);
  const [localInactiveDevices, setLocalInactiveDevices] =
    useState<ApiListResponse<StreamingDevice>>();
  const [localActiveDevices, setLocalActiveDevices] = useState<ApiListResponse<StreamingDevice>>();
  const [currentPageActive, setCurrentPageActive] = useState<number>(0);
  const [selectedDevice, setSelectedDevice] = useState<StreamingDevice | undefined>();
  const [sortField, setSortField] = useState<string | undefined>();
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | undefined>();
  const [prevOrganizationId, setPrevOrganizationId] = useState<string | undefined>();
  const [deviceType, setDeviceType] = useState<StreamingDeviceType | string>(
    DEVICE_TYPE_FILTER_OPTIONS[0].value
  );
  const [isFilterChange, setIsFilterChange] = useState<boolean>(false);
  const [paginationKey, setPaginationKey] = useState(0);

  const [allOrganizationFlag, setAllOrganizationFlag] = useState<boolean>(false);

  const sortingQueryParam =
    sortField && sortDirection ? `${sortField}:${sortDirection}` : undefined;
  const [organization, setOrganization] = useState<
    (OrganizationNameAndID & { banner_url: string; logo_url: string }) | undefined
  >({
    name: '',
    id: '',
    banner_url: '',
    logo_url: ''
  });

  const [getStreamingDevice, { isLoading: isLoadingGetStreamingDevice }] =
    useLazyGetStreamingDeviceQuery();

  const [getOrganizationByIdQuery, { isLoading: isLoadingOrgById }] =
    useLazyGetOrganizationByIdQuery();

  const { data: organizations, isLoading: isLoadingOrganizations } = useOrganizationsQuery(
    { q: 'active:true' },
    {
      skip: !user || user?.role !== ROLES.VAR
    }
  );

  const {
    data: orgDevicesActive,
    isLoading: isLoadingOrgDevicesActive,
    isFetching: isFetchingOrgDevicesActive,
    refetch: refetchActive,
    isUninitialized: isUninitializedActive
  } = useGetStreamingDevicesByOrganizationIdQuery(
    {
      organizationId: user?.role === ROLES.VAR ? organization?.id : user?.organization?.id,
      active: true,
      page_size: PAGE_SIZE,
      page: currentPageActive,
      ...(deviceType && { q: `device_type:${deviceType}` })
    },
    {
      skip: !user || (user?.role === ROLES.VAR && (!organization || !organization?.id)),
      refetchOnMountOrArgChange: true
    }
  );

  const {
    data: orgDevicesInactive,
    isLoading: isLoadingOrgDevicesInactive,
    isFetching: isFetchingOrgDevicesInactive,
    refetch: refetchInactive,
    isUninitialized: isUninitializedInactive
  } = useGetStreamingDevicesByOrganizationIdQuery(
    {
      organizationId: user?.role === ROLES.VAR ? organization?.id : user?.organization?.id,
      active: false,
      sort: sortingQueryParam,
      ...(deviceType && { q: `device_type:${deviceType}` })
    },
    { skip: !user || (user?.role === ROLES.VAR && (!organization || !organization?.id)) }
  );

  const { data: orgDevice, isLoading: isLoadingOrgDevice } = useGetStreamingDeviceQuery(
    { id: id || '' },
    {
      skip: !id || !!orgDevicesActive?.items.find((device) => device.id === selectedDevice?.id),
      refetchOnMountOrArgChange: true
    }
  );

  const { data: dataEvents, refetch: refetchActiveEvents } = useEventsQuery({ q: `state:active` });

  const organizationOptions = useMemo(() => {
    const alCompaniesOption = {
      value: 'all',
      label: 'All Organizations',
      banner_url: liveStreams,
      logo_url: defaultUser
    };

    return user?.role === ROLES.VAR
      ? [alCompaniesOption].concat(
          organizations?.items.map((org) => ({
            value: org.id,
            label: org.name,
            banner_url: org?.banner_url || '',
            logo_url: org?.logo_url || ''
          })) || []
        )
      : [];
  }, [organizations, user]);

  useTitle(selectedDevice?.name || 'Live Streams');

  useEffect(() => {
    if (prevOrganizationId !== organization?.id) {
      setLocalInactiveDevices(undefined);
      setPrevOrganizationId(organization?.id);
    } else if (localInactiveDevices && orgDevicesInactive && !isFilterChange) {
      const previouslyInactiveDevices = localInactiveDevices.items.filter(
        (device) =>
          !orgDevicesInactive.items.some((d) => d.id === device.id) &&
          device.organization.id === organization?.id
      );

      const checkDeviceExists = async () => {
        for (const device of previouslyInactiveDevices) {
          const { data: deviceData, isSuccess } = await getStreamingDevice({ id: device.id });

          if (
            isSuccess &&
            deviceData &&
            device.in_app_notifications &&
            user?.in_app_notifications &&
            prevOrganizationId === organization?.id
          ) {
            toast(
              <div>
                The <Link to={`/${device.id}`}>{device.name}</Link> Stream is now available
              </div>
            );
          }
        }
      };

      if (prevOrganizationId === organization?.id) {
        checkDeviceExists();
      }

      setIsFilterChange(false);
    }
  }, [orgDevicesInactive, localInactiveDevices, organization, prevOrganizationId, isFilterChange]);

  useEffect(() => {
    if (id && orgDevice && !initialDeviceSet) {
      setSelectedDevice(orgDevice);
      setInitialDeviceSet(true);
    }
  }, [id, orgDevice, initialDeviceSet]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setIsFilterChange(() => false);

        if (!isUninitializedActive && refetchActive) {
          refetchActive();
          refetchActiveEvents();
        }

        if (!isUninitializedInactive && refetchInactive) {
          refetchInactive();
        }
      },
      user && user.role === ROLES.VAR ? 15000 : 30000
    );

    return () => clearInterval(interval);
  }, [isUninitializedActive, isUninitializedInactive, refetchActive, refetchInactive]);

  useEffect(() => {
    setLocalActiveDevices(orgDevicesActive);
    setLocalInactiveDevices(orgDevicesInactive);
  }, [orgDevicesInactive, orgDevicesActive]);

  useEffect(() => {
    if (organizations) {
      const currentOrg = localStorage.getItem('currentOrg');

      if (user && user.role === ROLES.VAR) {
        setOrganization({
          id: 'all',
          name: 'All Organizations',
          banner_url: liveStreams,
          logo_url: defaultUser
        });
        setAllOrganizationFlag(true);
      } else {
        if (currentOrg) {
          setOrganization(JSON.parse(currentOrg));
        } else {
          setOrganization(organizations?.items[0]);
        }
      }
    }
  }, [organizations]);

  useEffect(() => {
    const fetchOrg = async () => {
      if (user && user.role !== ROLES.VAR) {
        try {
          const org = await getOrganizationByIdQuery({ id: user.organization.id }).unwrap();
          setOrganization(org);
        } catch (e) {
          handleCatch(e);
        }
      }
    };

    fetchOrg();
  }, [user]);

  useEffect(() => {
    if (selectedDevice && playerRef.current) {
      playerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedDevice]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPageActive]);

  const handlePageChangeActive = (newPage: number) => {
    setCurrentPageActive(newPage);
  };

  const handleSelectOrg = (
    newValue: string | number | boolean | (string | number | boolean)[] | undefined
  ) => {
    const selectedOrganization = organizationOptions.find((org) => org.value === newValue);
    const newOrganization = {
      id: newValue as string,
      name: selectedOrganization?.label || '',
      banner_url: selectedOrganization?.banner_url || '',
      logo_url: selectedOrganization?.logo_url || ''
    };

    setAllOrganizationFlag(newOrganization.id === 'all');

    setOrganization(newOrganization);
    setCurrentPageActive(0);
    setDeviceType(DEVICE_TYPE_FILTER_OPTIONS[0].value);
    setPaginationKey((prev) => prev + 1);

    localStorage.setItem('currentOrg', JSON.stringify(newOrganization));
  };

  const handleSortChange = (sort: Sort<StreamingDevice>) => {
    setSortField(sort.key);
    setSortDirection(sort.dir);
  };

  const handleDeviceTypeChange = (value: StreamingDeviceType) => {
    setDeviceType(value);
    setIsFilterChange(true);
    setCurrentPageActive(0);
    setPaginationKey((prev) => prev + 1);
  };

  const handleSelectDevice = (device: StreamingDevice) => {
    navigate(`/${device.id}`, { replace: true });
    setSelectedDevice(device);
  };

  const isLoading = useMemo(
    () =>
      isFetchingOrgDevicesActive ||
      isFetchingOrgDevicesInactive ||
      isLoadingOrgDevicesInactive ||
      isLoadingOrganizations ||
      isLoadingOrgDevicesActive ||
      isLoadingGetStreamingDevice ||
      isLoadingOrgById ||
      isLoadingOrgDevice,
    [
      isLoadingGetStreamingDevice,
      isLoadingOrgById,
      isLoadingOrgDevicesInactive,
      isLoadingOrganizations,
      isLoadingOrgDevicesActive,
      isLoadingOrgDevice
    ]
  );

  const renderPlayer = useMemo(
    () =>
      selectedDevice && selectedDevice.viewable ? (
        <>
          <div className="dashboard-stream-title-container" ref={playerRef}>
            <h2>{selectedDevice.name}</h2>
            <CloseIcon
              className="dashboard-close-icon"
              onClick={() => {
                setSelectedDevice(undefined);
                navigate('/', { replace: true });
              }}
            />
          </div>

          <ReactPlayer
            videoId={selectedDevice.video_id}
            adConfigId={selectedDevice.playback_token}
          />

          {user?.role === ROLES.VAR && (
            <InputField
              disabled
              type="text"
              className="dashboard-entry-point"
              value={selectedDevice.entry_point}
              id="entry-point"
              label="Entry Point"
              isCopyable
            />
          )}
        </>
      ) : null,
    [selectedDevice]
  );

  return (
    <div className="dashboard">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <CoverImage
            logoImage={organization?.logo_url || defaultUser}
            backgroundImage={organization?.banner_url || liveStreams}
            title={organization?.name || user?.organization.name || 'Live Streams'}
          />
          <div className="dashboard-container">
            <div className="dashboard-select-container">
              <Select
                options={DEVICE_TYPE_FILTER_OPTIONS}
                value={deviceType}
                onChange={(value) => handleDeviceTypeChange(value as StreamingDeviceType)}
                id="device-type"
                className="dashboard-organization-select"
                label="Filter by"
              />

              {user?.role === ROLES.VAR ? (
                <Select
                  options={organizationOptions}
                  value={organization?.id}
                  onChange={(value) => handleSelectOrg(value)}
                  id="organization"
                  className="dashboard-organization-select"
                  label="Select Organization"
                />
              ) : null}
            </div>

            {renderPlayer}

            <VideoGrid
              devices={localActiveDevices?.items}
              onSelectDevice={handleSelectDevice}
              varAdmin={user?.role === ROLES.VAR}
              allOrganizationFlag={allOrganizationFlag}
              dataEvents={dataEvents?.items}
            />

            {!!localActiveDevices?.items.length && (
              <Pagination
                key={paginationKey}
                total={localActiveDevices?.total}
                pageSize={PAGE_SIZE}
                onChange={handlePageChangeActive}
              />
            )}

            {organization?.id !== 'all' && (
              <h2 className="dashboard-inactive-streams-title">Inactive Streams</h2>
            )}

            {organization?.id !== 'all' && (
              <CNCTable<StreamingDevice>
                className="event-form-devices-table"
                data={localInactiveDevices}
                columns={inactiveDevicesColumns()}
                loading={isLoading}
                scrollX={300}
                scrollY={350}
                showPagination={false}
                onSort={handleSortChange}
              />
            )}
          </div>
        </>
      )}
      <ToastContainer position="bottom-right" newestOnTop autoClose={10000} />
    </div>
  );
};

export default Dashboard;
