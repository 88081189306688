/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import ReactPlayerLoader from '@brightcove/react-player-loader';

import Loader from '../Loader';
import config from '../../config';

import './ReactPlayer.scss';

interface Options {
  autoplay?: boolean;
  muted?: boolean;
  loop?: boolean;
  controls?: boolean;
}

interface ReactPlayerProps {
  options?: Options;
  videoId: string;
  adConfigId?: string;
}

const ReactPlayer: FC<ReactPlayerProps> = ({ videoId, adConfigId, options = {} }) => {
  // had to type is as any because there are no types for BC player
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [playerRef, setPlayerRef] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (playerRef && typeof playerRef.dispose === 'function') {
      playerRef.dispose();
    }
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccess = (success: any): void => {
    const player = success.ref;
    if (player && typeof player.lowLatencyReconnect === 'function') {
      player.lowLatencyReconnect();
    } else {
      console.error('Something went wrong loading the CncReconnectPlugin plugin');
    }
    setPlayerRef(player);
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <ReactPlayerLoader
        attrs={{
          className: 'ReactPlayer'
        }}
        accountId={config.accountId}
        videoId={videoId}
        adConfigId={adConfigId}
        playerId={config.playerId}
        options={options}
        embedOptions={{
          responsive: true
        }}
        onSuccess={onSuccess}
        onEmbedCreated={() => setLoading(true)}
        onFailure={(e: unknown) => {
          // eslint-disable-next-line no-console
          console.log('Player Error: ', e);
        }}
      />
    </>
  );
};

export default ReactPlayer;
